import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../../components/layout.js"

const Article = ({ data }) => {
    const hero_image = getImage(data.mdx.frontmatter.hero_image);

    return (
        <Layout pageTitle={data.mdx.frontmatter.title} titleClass="blog-post">
            <p className="mb-5">{data.mdx.frontmatter.date}</p>
            <GatsbyImage
                image={hero_image}
                alt={data.mdx.frontmatter.hero_image_alt}
            />
            <p className="hero-image-caption">
                Photo credit:{" "}
                <a href={data.mdx.frontmatter.hero_image_credit_link} title={data.mdx.frontmatter.hero_image_credit_text}>
                    {data.mdx.frontmatter.hero_image_credit_text}
                </a>
            </p>
            <MDXRenderer>
                {data.mdx.body}
            </MDXRenderer>
        </Layout>
    )
}

export default Article

export const query = graphql`
query ($id: String) {
    mdx(id: {eq: $id}) {
        frontmatter {
            title
            date(formatString: "MMMM D, YYYY")
            hero_image_alt
            hero_image_credit_link
            hero_image_credit_text
            hero_image {
                childImageSharp {
                    gatsbyImageData
                }
            }
        }
        body
    }
  }
`
